// JSDoc (http://usejsdoc.org/)
/** @module permisosService */


export default class permisosService{
    /**
     * @param axios Axios Instance
     */
    constructor(axios){
        this.axios =  axios;
    }

    async getModulo() {
        return this.axios.get('/Permisos/GetModulos').then(res => res.data);
    }
    async createModulo(data) {
        var config = {
            method: 'post',
            url: '/Permisos/CreateModulo',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data.mod_nombre
          };
        
        return  this.axios(config).then(res => res.data);
    }

    async upsertMenu(data){
        return  this.axios.post(`/Permisos/CreateMenu`,data,{
            headers: { 
                'Content-Type': 'application/json'
            },
        } ).then(res => res.data);
    }

    async getMenu(mod_codigo, men_padre, page = 1, pageSize = 1000){
        return  this.axios.get(`/Permisos/GetMenus`,{
            params: {
                page : page,
                pageSize : pageSize,
                mod_codigo : mod_codigo,
                men_padre : men_padre
            }}).then(res => res.data);
    }

    async createRol(data){
        var config = {
            method: 'post',
            url: '/Permisos/CreateRol',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data.rol_nombre
          };
        
        return  this.axios(config).then(res => res.data);
    }

    async updateRol(data){
        var config = {
            method: 'put',
            url: `/Permisos/UpdateRol/${data.rol_codigo}`,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
        
        return  this.axios(config).then(res => res.data);
    }

    async getRoles(page = 1, pageSize = 10, searchterm =  ""){
        return  this.axios.get(`/Permisos/GetRoles`,{
            params: {
                page : page,
                pagesize : pageSize,
                searchterm : searchterm  === "" ? null : searchterm
            }
        }).then(res => res.data);
    }


    //#region Usuarios
    async createUsuario(data){
        return  this.axios.post(`/Permisos/CreateUsuario`,data,{
            headers: { 
                'Content-Type': 'application/json'
            },
        } ).then(res => res.data);
    }

    async enableUsuario(usr_codigo, usr_activo){
        var config = {
            method: 'put',
            url: `/Permisos/EnableUsuario/${usr_codigo}`,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : usr_activo
          };
        
        return  this.axios(config).then(res => res.data);
    }


    async resetPassUsuario(usr_codigo,newPassword){
        var config = {
            method: 'put',
            url: `/Permisos/ResetPassUsuario/${usr_codigo}`,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : newPassword
          };
        
        return  this.axios(config).then(res => res.data);
    }


    async getUsuarios(page = 1, pageSize = 10, searchterm =  ""){
        return  this.axios.get(`/Permisos/GetUsuarios`,{
            params: {
                page : page,
                pagesize : pageSize,
                searchterm : searchterm  === "" ? null : searchterm
            }}).then(res => res.data);
    } 

    async getUsuarioById(codigo){
        return  this.axios.get(`/Permisos/GetUsuarioById/${codigo}`).then(res => res.data);
    } 


    //#region Restriccion
    async getRestriccionesRol(mod_codigo, rol_codigo){
        return  this.axios.get(`/Permisos/GetRestricciones`,{
            params: {
                mod_codigo : mod_codigo,
                rol_codigo : rol_codigo
            }}).then(res => res);
    } 
    
    async getRestriccionesMenu(men_codigo){
        return  this.axios.get(`/Permisos/GetRestriccionesMenu/${men_codigo}`).then(res => res.data);
    }

    async createRestriccion(data){
        return  this.axios.post(`/Permisos/CreateRestriccion`,data,{
            headers: { 
                'Content-Type': 'application/json'
            },
        } ).then(res => res.data);
    }

    async updateRestriccion(data){
        return  this.axios.put(`/Permisos/UpdateRestriccion/${data.mer_codigo}`,data,{
            headers: { 
                'Content-Type': 'application/json'
            },
        } ).then(res => res.data);
    }
}
