<template>
   <!-- Lista de usuario y nuevos usuarios --> 
   <div class="p-card">
        <div class="p-card-title">
            <div class="card-header">
                <div class="card-title">
                    <h5 class="ml-3 font-semibold text-gray-900">Administración de usuarios</h5>
                </div>
                <div class="card-toolbar">
                    <Button label="Nuevo"  icon="pi pi-plus" class="p-button-primary ml-2"  @click="upsertUser_onClick()"/>
                </div>
            </div>
        </div>
         <span class="p-input-icon-left w-full p-input-icon-right">
            <i class="pi pi-search" />
            <InputText  placeholder="Buscar usuario..."  class="w-full" v-model="searchTerm" @keyup="handleKeyUp" @keypress="handleKeyPress"/>
             <i class="pi pi-spin pi-spinner" v-if="loading"/>
        </span>
    </div>

    <div class="p-card my-3 shadow-2 hover:bg-blue-50 " v-for="user in userList" :key="user">
        <div class="flex flex-column md:flex-row md:justify-content-between">
            <div class="flex  align-items-center my-1">
                <div class="mx-3">
                    <div class="shadow-3 symbol symbol-50 symbol-circle " :class="'symbol-light-'+ (user.usr_activo == true ? 'info' :  'danger')">
                        <span class="symbol-label font-semibold">{{user.usr_nombre[0].toUpperCase()}}</span>
                    </div>
                </div>
                <div class="flex-grow-1 mb-1">
                    <a href="javascript:void(0)" class="text-gray-900 font-bold  hover:text-primary text-lg">{{user.usr_usuario}}</a>
                    <span class="text-gray-500 font-semibold block text-lg"> {{user.usr_nombre}} {{user.usr_apellido}} </span> 
                </div>
            </div>
            <div class="flex w-20rem align-items-center justify-content-center" >
                <div class="mt-2">
                    <Chip :label="rol.rol_nombre"  icon="pi pi-lock" class="text-blue-700 mx-2"  v-for="rol in user.rolList" :key="rol" />
                </div>
            </div>
            <div class="flex my-1 justify-content-end">
                <div class="flex-none flex align-items-center " style="pointer-events: none;">
                    <div class="field-checkbox my-0">
                        <InputSwitch v-model="user.usr_activo" /> 
                        <label></label> 
                    </div>
                </div>
                <div class="flex align-items-center justify-content-end">
                    <div class="flex h-3rem ">
                        <a href="javascript:void(0)" class="btn btn-bg-light px-2 mx-1" v-tooltip.top="'Editar'" @click="upsertUser_onClick(user)"> 
                            <i class="pi pi-pencil text-primary"></i>
                        </a>
                        <a href="javascript:void(0)" class="btn btn-bg-light px-2 mx-1" v-tooltip.top="'Contraseña'" @click="resetPassword_onClick(user)"> 
                            <i class="pi pi-replay text-primary"></i>
                        </a>
                        <a href="javascript:void(0)" class="btn btn-bg-light px-2 mx-1" v-tooltip.top="(user.usr_activo ? 'Desactivar' : 'Activar')" @click="unableUser_onClick(user)"> 
                            <i class="pi" :class="(user.usr_activo ? 'pi-times text-danger' : 'pi-check text-success')"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <div class="block">
        <Paginator :rows="10" :totalRecords="userPagination.totalRecords" 
            template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[10,20,50,100]"
                currentPageReportTemplate="Mostrando {first} - {last} de {totalRecords} Usuarios" @page="onPage($event)"></Paginator>

    </div>
     <!-- Begin: Create User -->
    <Dialog  v-model:visible="isAddUser" :modal="true" :style="{width: '460px'}" :closable="formSelected === FORMS.CREATE">
        <template #header>
            <div class="block">
                <h5 class="font-semibold text-gray-900 m-0">CONTROL DE USUARIOS</h5>
                <span class="text-sm text-muted text-semibold" v-if="formSelected == FORMS.RESET">Reestablecer contraseña</span>
            </div>
        </template>
        <CreateUsuario :Usuario="user" @cancel_onEmit="isAddUser =  false" @save_onEmit="save_onClick()" :Service="Service" v-if="formSelected === FORMS.CREATE"/>
        <ResetPassword :Usuario="user" @cancel_onEmit="isAddUser =  false" @save_onEmit="save_onClick()" :Service="Service" v-if="formSelected === FORMS.RESET"/>
    </Dialog>
    <!-- End:  Create User -->
</template>

<script>

import CreateUsuario from '@/components/Admin/Seguridad/CreateUsuario'
import ResetPassword from '@/components/Admin/Seguridad/ResetPassword'

let timer, 
timeoutVal = 1000;

const FORMS = {
    CREATE : "create",
    RESET : "Reset"
}
export default {
    props:{
        Service: null
    },
    data(){
        return{
            userList: [],
            
            userPagination : {},

            user : {
                usr_codigo:0,
                usr_usuario:"",
                usr_nombre:"",
                usr_apellido:"",
                usr_activo:true
            },
            searchTerm : "",
            FORMS : FORMS,
            formSelected :  undefined,

            //Dialogs
            isAddUser : false,
            loading : false,
        }
    },

    async mounted(){
        await this.fxInit();
    },
    methods: {

        async fxInit(page = 1, pageSize = 10, searchTerm =  null){
            await this.Service.getUsuarios(page,pageSize,searchTerm).then(res => {
                this.userList =  res.data
                this.userPagination = {
                    page : res.currentPage,
                    pageSize : res.pageSize,
                    totalRecords : res.totalRecords
                }
            });

            console.log(this.userList)
        },

        async unableUser_onClick(userData){

            if(userData.usr_codigo == 1)
            {
                this.$toast.add({severity:'warn', summary: '¡Cuidado!', detail:"No se puede desactivar el usuario principal", life: 1500});  
                return;
            }
            this.$confirm.require({
                message: `¿Está seguro de ${(!userData.usr_activo ? "activar" : "desactivar")}  este registro?`,
                header: 'Activar/Desactivar usuario',
                icon: 'pi pi-info-circle',
                rejectClass: "p-button-danger p-button-text",
                acceptClass: 'p-button-primary',
                rejectLabel: "No, cancelar",
                accept: async () => {
                    try{

                        await this.Service.enableUsuario(userData.usr_codigo, !userData.usr_activo).then(res=> {
                            this.$toast.add({severity:'info', summary: '¡Éxito!', detail:res, life: 1500});  
                            userData.usr_activo =  !userData.usr_activo
                        })
                    }
                    catch(ex){
                        if(ex.response){
                            this.$toast.add({severity:'error', summary: '¡Error al realizar la operación!', detail:ex.response.data , life: 1500});  
                        }else{
                            this.$toast.add({severity:'error', summary: '¡Error al realizar la operación!', detail:'Msg:' + ex, life: 1500});  
                        }
                    }
                },
            });
        },

        async resetPassword_onClick(userData){
         
            this.formSelected =  this.FORMS.RESET
            this.$confirm.require({
                message: `¿Está seguro de reestablecer la contraseña?`,
                header: 'Reestablecer contraseña',
                icon: 'pi pi-info-circle',
                rejectClass: "p-button-danger p-button-text",
                acceptClass: 'p-button-primary',
                rejectLabel: "No, cancelar",
                accept: async () => {
                    this.user = userData
                    this.isAddUser =  true
                },
            });
        },
        async upsertUser_onClick(userData = null){
            this.formSelected =  this.FORMS.CREATE
            this.isAddUser =  true
            if(!userData){
                this.user = {
                    usr_codigo:0,
                    usr_usuario:"",
                    usr_nombre:"",
                    usr_apellido:"",
                    usr_activo:true
                }
            }else{
                this.user = userData
            }
        },
         save_onClick(){
            this.isAddUser = false;
        },

        async onPage(event) {
            //event.page: New page number
            //event.first: Index of first record
            //event.rows: Number of rows to display in new page
            //event.pageCount: Total number of pages
            this.fxInit(++event.page, event.rows,null);
        },

         // eslint-disable-next-line no-unused-vars
        handleKeyPress(e) {
            window.clearTimeout(timer);
        },
        // eslint-disable-next-line no-unused-vars
        handleKeyUp(e) {
            this.loading =  true;
            window.clearTimeout(timer); 
            timer = window.setTimeout(() => {
                var page = 1
                this.fxInit(page, this.userPagination.pageSize,this.searchTerm);
                this.loading =  false;
            }, timeoutVal);

        },
       

     
    },
    components:{
        CreateUsuario,
        ResetPassword
    }
}
</script>

<style  lang="scss" scoped>
::v-deep(.p-paginator) {
    .p-paginator-current {
        margin-left: auto!important
    }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to  {
  opacity: 0;
}
</style>