<template>
     <form autocomplete="off" @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="flex justify-content-end">
            <Chip :label="Usuario.usr_nombre" class="mr-2 mb-2" icon="pi pi-users"/>
        </div>
        <div class="p-fluid">
           
            <div class="field">
                <label for="usr_acceso" class="font-bold">Contraseña</label>
                <Password toggleMask id="usr_acceso" v-model="password" required="true" autofocus :class="{'p-invalid': submitted && !password}"  placeholder=" Clave de acceso " :feedback="false"/>
                <small class="p-error" v-if="v$.password.required.$invalid && submitted">* Valor Requerido</small>
                <small class="p-error" v-if="v$.password.minLength.$invalid  && submitted">{{v$.password.minLength.$message }} </small>
            </div>
            <div class="field">
                <label for="usr_acceso2" class="font-bold">Confirmar contraseña</label>
                <Password toggleMask id="usr_acceso2" v-model="repeatPassword" required="true" autofocus :class="{'p-invalid': submitted && !repeatPassword}"  placeholder=" Clave de acceso "  :feedback="false"/>
                <small class="p-error" v-if="v$.repeatPassword.sameAs.$invalid  && submitted">El valor debe coincidir</small>
             
            </div>
        </div>
        <div class="flex justify-content-between">
            <div class="flex justify-content-start">
                <Button type="button" label="Defecto" class="p-button-text p-button-info mx-1 " @click="password = 'ugb2021'; repeatPassword = 'ugb2021'"/>
            </div>
            <div class="flex justify-content-end">
                <Button type="button" label="Cancelar" class="p-button-text p-button-danger mx-1 " @click="cancelReset_onClick()"/>
                <Button  label="Guardar" type="submit" />
            </div>
        </div>
     </form>
</template>

<script>
import { required,sameAs, minLength,helpers   } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
export default {
    emits: ["cancel_onEmit", "save_onEmit"],
    props:{
        Usuario:{
            type : Object,
            default(){
                return {}
            }
        },
        Service :  null,
    },
    data(){
        return{
            password: '',
            repeatPassword: '' ,
            submitted :  false
        }
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
        return {
            password: {
                required,
                minLength: helpers.withMessage(
                    ({
                        $params,
                    }) => `*El campo debería tener un mínimo de ${$params.min}`,
                    minLength(6)
                )
            },
            repeatPassword: {
                required,
                minLength: helpers.withMessage(
                    ({
                        $params,
                    }) => `*El campo debería tener un mínimo de ${$params.min}`,
                    minLength(6)
                ),
                sameAs : sameAs(this.password)
            }
        }
    },
    methods:{
        handleSubmit(isFormValid){
            try{
                this.submitted = true;
                if (!isFormValid) {
                    return;
                }
                this.$confirm.require({
                    message: `¿Está seguro de cambiar la contraseña?`,
                    header: 'Cambiar Contraseña',
                    icon: 'pi pi-info-circle',
                    rejectClass: "p-button-danger p-button-text",
                    acceptClass: 'p-button-info',
                    accept: async () => {
                        try{
                            await this.Service.resetPassUsuario(this.Usuario.usr_codigo, this.password).then(res => res);
                            this.$toast.add({severity:'info', summary: '¡Éxito!', detail:'Acción realizada con éxito', life: 1500});  
                            this.$emit("save_onEmit")
                        }
                        catch(ex){
                            if(ex.response){
                                this.$toast.add({severity:'error', summary: '¡Error al realizar la operación!', detail:ex.response.data , life: 1500});  
                            }else{
                                this.$toast.add({severity:'error', summary: '¡Error al realizar la operación!', detail:'Msg:' + ex, life: 1500});  
                            }
                        }
                    },
                });
            }
            catch(ex){
                this.$catchError(ex);
            }
        },
        cancelReset_onClick(){
            this.$emit("cancel_onEmit")
        },
    }
}
</script>