<template>
    <form autocomplete="off">
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="col-12 ">
                    <div class="field">
                        <label for="prp_empleado" >Empleado </label>
                        <div for="prp_empleado" class="p-inputgroup">
                            <Button label="Buscar" @click="openDialogEmpleado_onClick()"/>
                            <InputText placeholder="Empleado..." disabled  :value="resultSelectedEmpleado.emp_nombres_apellidos"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="field">
                <label for="usr_usuario" class="font-bold">Usuario</label>
                <InputText id="usr_usuario" v-model.trim="eUsuario.usr_usuario" required="true" autofocus :class="{'p-invalid': submitted && !eUsuario.usr_usuario}"  placeholder="Nombre de usuario" />
                <small class="p-error" v-if="submitted && !eUsuario.usr_usuario">* Usuario requerido.</small>
            </div>
            <div class="field">
                <label for="usr_acceso" class="font-bold">Contraseña</label>
                <Password toggleMask id="usr_acceso" v-model="eUsuario.usr_acceso" required="true" autofocus :class="{'p-invalid': submitted && !eUsuario.usr_acceso}"  placeholder=" Clave de acceso " :feedback="false"/>
                <small class="p-error" v-if="submitted">* Valor Requerido</small>
            </div>
            <div class="formgrid grid">
                <div class="col-12  md:col-6">
                    <div class="field">
                        <label for="usr_nombre">Nombre</label>
                        <InputText id="usr_nombre" v-model.trim="eUsuario.usr_nombre" required="true" autofocus :class="{'p-invalid': submitted && !eUsuario.usr_nombre}"  placeholder="Nombre de empleado" />
                        <small class="p-error" v-if="submitted && !eUsuario.usr_nombre">* Nombre requerido.</small>
                    </div>
                </div>

                <div class="col-12  md:col-6">
                    <div class="field">
                        <label for="usr_apellido">Apellido</label>
                        <InputText id="usr_apellido" v-model.trim="eUsuario.usr_apellido" required="true" autofocus :class="{'p-invalid': submitted && !eUsuario.usr_apellido}"  placeholder="Apellido de empleado" />
                        <small class="p-error" v-if="submitted && !eUsuario.usr_apellido">* Apellido requerido.</small>
                    </div>
                </div>
            </div>
           
        </div> 
        <div class="flex justify-content-end">
            <Button type="button" label="Cancelar" class="p-button-text p-button-danger mx-1 " @click="cancelUsuario_onClick()"/>
            <Button type="button" label="Guardar" class="mx-2" @click="saveUsuario_onClick()" />
        </div>
    </form>


    <!--Begin : Empleado Dialog -->
    <Dialog v-model:visible="isDialogEmpleado" :breakpoints="{'1360px': '95vw'}" :style="{width: '50vw'}" :modal="true">
        <template #header>
             <h5 class=" font-semibold text-gray-900 flex align-items-center" ><GroupIco size="svg-icon-1"  class="mr-2"/> BUSCAR EMPLEADO</h5>
        </template>
        <EmpleadoDataTable  v-model:selectedItems="resultSelectedEmpleado" :Mode="'single'"> </EmpleadoDataTable>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times"  class="p-button-text" @click="cancelEmpleado_onClick()"/>
            <Button label="Aceptar" icon="pi pi-check" class=""  @click="selectedEmpleado_onClick()"  />
        </template>
    </Dialog>
</template>

<script>

import EmpleadoDataTable from '@/components/Generales/EmpleadoDt'
import {Group} from  "@/components/svg/_svgHelper"

export default {
    emits: ["cancel_onEmit", "save_onEmit"],
    props:{
        Usuario : {
            type : Object,
            default(){
                return {}
            }
        },
        Service :  null
    },
    data(){
        return{
            submitted : false,
            eUsuario : this.Usuario,
            isDialogEmpleado :  false,
            resultSelectedEmpleado : {
                emp_codigo : this.Usuario.usr_codemp ,
                emp_nombres_apellidos : `${this.Usuario.usr_nombre} ${this.Usuario.usr_apellido}`
            }
        }
    },
    methods:{
        cancelUsuario_onClick(){
            this.$emit("cancel_onEmit")
        },
        saveUsuario_onClick(){
            this.submitted =  true;
            if(!this.eUsuario.usr_usuario || !this.eUsuario.usr_usuario.trim() || !this.eUsuario.usr_acceso.trim() || !this.eUsuario.usr_nombre.trim()
            || !this.eUsuario.usr_apellido.trim()){
                
                return;
            }

            this.$confirm.require({
                message: `¿Está seguro de guardar  este registro?`,
                header: 'Guardar Menu',
                icon: 'pi pi-info-circle',
                rejectClass: "p-button-danger p-button-text",
                acceptClass: 'p-button-info',
                accept: async () => {
                    try{
                        await this.Service.createUsuario(this.eUsuario).then(res => this.eUsuario =  res);
                        this.$toast.add({severity:'info', summary: '¡Éxito!', detail:'Acción realizada con éxito', life: 1500});  
                        this.$emit("save_onEmit",this.eUsuario)
                    }
                    catch(ex){
                     
                        if(ex.response){
                            this.$toast.add({severity:'error', summary: '¡Error al realizar la operación!', detail:ex.response.data , life: 1500});  
                        }else{
                            this.$toast.add({severity:'error', summary: '¡Error al realizar la operación!', detail:'Msg:' + ex, life: 1500});  
                        }
                    }
                },
            });
        },

        openDialogEmpleado_onClick(){
            this.isDialogEmpleado =  true;
        },
        cancelEmpleado_onClick(){
            this.isDialogEmpleado =  false;
        },  
        selectedEmpleado_onClick(){
            var result =  {... this.resultSelectedEmpleado }
            this.eUsuario.usr_codemp = result.emp_codigo
            this.isDialogEmpleado =  false;
        },
    },
    components :{
        EmpleadoDataTable,
        GroupIco : Group
    }
}
</script>