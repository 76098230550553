<template>
    <div class="grid">
        <div class="col-12 md:col-3 xl:col-2 xl:col-offset-2">
            <div class="p-card">
                <div class="p-card-title">
                    <div class="card-header">
                        <div class="card-title">
                            <h5 class="ml-3 font-normal text-gray-900 py-0 my-0">Control de Seguridad</h5>
                        </div>
                    </div>
                </div>
                <Menu :model="menuSeguridad" class="w-full"></Menu>
            </div>
        </div>
        <div class="col-12 md:col-8 xl:col-6" >
            <UsuarioTab v-if="selectedTab ==  TAB.Usuarios" :Service="permisosService"/>
            <RolTab v-if="selectedTab == TAB.Roles"  :Service="permisosService"/>
        </div>
    </div>


   
</template>

<script>
import UsuarioTab from '@/components/Admin/Seguridad/UsuarioTab.vue'
import RolTab from '@/components/Admin/Seguridad/RolTab.vue'
import PermisosService from '@/service/permisosService'

const tab = {
    Roles : "roles",
    Restriccion : "restriccion",
    Usuarios : "usuario"
}
export default {
    permisosService : null,
    data(){
        return{
            menuSeguridad: [],
            userList: [],


            //Dialogo
            isAddUser :  false,
            //Tab
            TAB : tab,
            selectedTab: null,
        }
    },
    created(){
        this.permisosService =  new PermisosService(this.axios);

    },
    mounted(){
        this.menuSeguridad = [
            {
                label: 'Accesos',
                items: [
                {
                    label: 'Usuarios',
                    icon: 'pi pi-users',
                    command: () => {
                        this.selectedTab  = this.TAB.Usuarios
                    }
                }
            ]},
            {
                label: 'Permisos',
                items: [{
                    label: 'Roles',
                    icon: 'pi pi-lock',
                    command: () => {
                        this.selectedTab  = this.TAB.Roles
                    }
                },
                {
                    label: 'Restricciones',
                    icon: 'pi pi-shield',
                    command: () => {
                        this.selectedTab  = this.TAB.Restriccion
                    }
                }
            ]},
        ]
        this.selectedTab  = this.TAB.Usuarios
    },
    methods: {
       
    },
    components:{
        UsuarioTab,
        RolTab
    }
}
</script>