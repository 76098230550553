<template>
   <!-- Lista de usuario y nuevos usuarios --> 
   <div class="p-card">
        <div class="p-card-title">
            <div class="card-header">
                <div class="card-title">
                    <h5 class="ml-3 font-semibold text-gray-900">Administración de Roles</h5>
                </div>
                <div class="card-toolbar">
                    <Button label="Nuevo"  icon="pi pi-plus" class="p-button-primary ml-2"  @click="upsertRol_onClick()"/>
                </div>
            </div>
        </div>
        <span class="p-input-icon-left w-full p-input-icon-right">
            <i class="pi pi-search" />
            <InputText  placeholder="Rol"  class="w-full" v-model="searchTerm" @keyup="handleKeyUp" @keypress="handleKeyPress"/>
             <i class="pi pi-spin pi-spinner" v-if="loading"/>
        </span>
        
    </div>

    <div class="p-card my-3 shadow-2 hover:bg-blue-50" v-for="rol in rolList" :key="rol">
        <div class="flex align-items-center">
            
            <div class="mx-3">
                <div class="shadow-3 symbol symbol-50 symbol-circle " :class="'symbol-light-' + (rol.rol_activo ? 'info' : 'danger')">
                    <span class="symbol-label font-semibold">{{rol.rol_nombre[0].toUpperCase()}}</span>
                </div>
            </div>
            <div class="flex-grow-1 mb-1">
                <a href="javascript:void(0)" class="text-gray-900 font-bold  hover:text-primary text-lg">{{rol.rol_nombre}}</a>
            </div>
            <div class="flex-none flex align-items-center " style="pointer-events: none;">
                <div class="field-checkbox">
                    <InputSwitch v-model="rol.rol_activo" /> 
                    <label></label> 
                </div>
            </div>
            <div class="flex justify-content-end">
                <a href="javascript:void(0)" class="btn btn-bg-light px-2 mx-2 flex flex-align-items-center" v-tooltip.top="'Editar'" @click="upsertRol_onClick(rol)" > 
                    <i class="pi pi-pencil text-primary"></i>
                </a>
            </div>
        </div>
    </div>


    <div class="block">
        <Paginator :rows="5" :totalRecords="rolPagination.totalRecords" 
            template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[5,10,20,50,100]"
                currentPageReportTemplate="Mostrando {first} - {last} de {totalRecords} Empleados" @page="onPage($event)">
        </Paginator>
    </div>
     <!-- Begin: Create Rol -->
    <Dialog  v-model:visible="isDialogRol" :modal="true" :style="{width: '460px'}">
        <template #header>
                <h5 class="font-semibold text-gray-900">CONTROL DE ROL</h5>
        </template>
        <div class="p-fluid">
            <div class="field">
                <label for="usr_usuario">Rol</label>
                <InputText id="usr_usuario" class="text-center" v-model.trim="rol.rol_nombre" required="true" autofocus :class="{'p-invalid': submitted && !rol.rol_nombre}"  placeholder="Nombre de rol" />
                <small class="p-error" v-if="submitted && !rol.rol_nombre">* Rol requerido.</small>
            </div>
            <div class="flex-none flex align-items-center justify-content-end" v-if="rol.rol_codigo > 0">
                <div class="field-checkbox">
                    <InputSwitch  id="mer_estado" v-model="rol.rol_activo" :binary="true"/> 
                    <label>Activo</label> 
                </div>
            </div>
            <div class="flex justify-content">
                <Button type="button" label="Cancelar" class="p-button-text p-button-danger mx-1 " @click="cancelRol_onClick()"/>
                <Button type="button" label="Guardar" class="mx-2" @click="saveRol_onClick()" />
            </div>
        </div>
    </Dialog>
    <!-- End: Create Rol-->
</template>

<script>

let timer,
timeoutVal = 1000;
export default {

    props:{
        Service : null
    },
    data(){
        return{
            rolList: [],
            rolPagination: {},
            rol : {
                rol_nombre : "",
                rol_activo : true
            },
            searchTerm : "",
            submitted : false,
            //Dialogs
            isDialogRol : false,
            loading:  false
        }
    },

    async mounted(){
        await this.fxInit();
    },
    methods: {
        async fxInit(page = 1, pageSize = 5, searchTerm =  null){

            await this.Service.getRoles(page,pageSize,searchTerm).then(res => {
               
                this.rolList =  res.data
                this.rolPagination = {
                    page : res.currentPage,
                    pageSize : res.pageSize,
                    totalRecords : res.totalRecords
                }
            });
        },
        
        async upsertRol_onClick(rolData =  null){
            if(!rolData){
                this.rol =  {
                    rol_codigo : 0,
                    rol_nombre : "",
                    rol_activo : true
                }
            }else{
                this.rol = {...rolData}
            }
            this.isDialogRol =  true
        },

        cancelRol_onClick(){
            this.isDialogRol  =  false;
        },
        async saveRol_onClick(){
            try{
                this.submitted = true;
                if(!this.rol.rol_nombre.trim()){
                    return;
                }

                //-------------------------------------//
                if(this.rol.rol_codigo == 0) //insert
                {
                    await this.Service.createRol(this.rol).then(res=> this.rol  =  res)
                    this.$toast.add({severity:'info', summary:'¡Éxito!', detail: "Rol creado con éxito", life: 3000});
                }else{ //Edit
                    await this.Service.updateRol(this.rol).then(res=> this.rol  =  res)
                    this.$toast.add({severity:'info', summary:'¡Éxito!', detail: "Rol actualizado con éxito", life: 3000});
                }
                await this.fxInit(this.rolPagination.page, this.rolPagination.pageSize);

                this.submitted = true;
                this.isDialogRol = false;
            }catch(ex){
                this.$toast.add({severity:'error', summary: '¡Error al realizar la operación!', detail:'Msg:' + ex, life: 1500});  
            }
        },

        async onPage(event) {
            //event.page: New page number
            //event.first: Index of first record
            //event.rows: Number of rows to display in new page
            //event.pageCount: Total number of pages
            this.fxInit(++event.page, event.rows,null);

        },
         // eslint-disable-next-line no-unused-vars
        handleKeyPress(e) {
            window.clearTimeout(timer);
        },
        // eslint-disable-next-line no-unused-vars
        handleKeyUp(e) {
            this.loading =  true;
            window.clearTimeout(timer); 
            timer = window.setTimeout(() => {
                var page = 1
                this.fxInit(page, this.rolPagination.pageSize,this.searchTerm);
                this.loading =  false;
            }, timeoutVal);

        }
    },
    components:{
    }
}
</script>

<style  lang="scss" scoped>
::v-deep(.p-paginator) {
    .p-paginator-current {
        margin-left: auto!important
    }
}
</style>